import Service from './Service';

class UserService extends Service {
    constructor() {
        super();
    }
    async userAcl() {
        return this.callApiGet(`/userAcl`);
    }
    async get(pageParam) {
        return this.callApiGet(`/user${pageParam}`);
    }
    async create(postData) {
        return this.callApiPost(`/user`, postData);
    }
    async update(postData) {
        return this.callApiPut(`/user`, postData);
    }
    async resetUserPassword(postData) {
        return this.callApiPut(`/resetUserPassword`, postData);
    }
    async changeAvatar(postData) {
        return this.callApiMultipart(`/changeAvatar`, postData);
    }
    async delete(id) {
        return this.callApiDelete(`/user?_ids=${id}`);
    }
}
export default UserService