<template>
  <v-container
    id="page-user"
    fluid
  >

    <base-wee-sketlon-loader
      :loading="state.loading"
      type="table-heading, table-thead, table-tbody, table-tfoot"
      :no="1"
    />

    <!-- Table  -->
    <wee-simple-table
      v-if="!state.loading"
      :headers="fillableHeaders"
      :title="$t('model.user.user')"
      :tr-list="filteredList"
      :pages="pages"
      :sort="sort"
      @update-search="searchTxt = $event"
      @on-item-click="onItemClick"
      @on-item-delete="onBeforeDeleteItem"
      @on-open-new-form="onOpenNewForm"
      @on-advance-search="advanceSearch"
      @on-reload-page="onReload"
    >
      <!-- <template v-slot:theader></template> 
      <template v-slot:tbody></template> 
      <template v-slot:tpaging></template>  -->
    </wee-simple-table>

    <user-form
      v-model="entity"
      :open="openNewForm"
      :processing="isProcessing"
      :roles="roleList"
      @close="openNewForm = false"
      @save="onSave"
      @reset-pwd="onResetUserPassword"
      :edit-mode="editMode"
    />
    <wee-confirm ref="weeConfirmRef"></wee-confirm>
    <wee-toast ref="weeToastRef"></wee-toast>
  </v-container>
</template>

<script>
//service
import RoleService from "@/api/RoleService";
import UserService from "@/api/UserService";
import useBase from "@/composition/UseBase";
import useCrudApi from "@/composition/UseCrudApi";
import { toRefs, onMounted, onBeforeUnmount, ref } from "@vue/composition-api";
export default {
  name: "page-user",
  components: {
    WeeConfirm: () => import("@/components/WeeConfirm"),
    WeeToast: () => import("@/components/WeeToast"),
    WeeSimpleTable: () => import("@/components/WeeSimpleTable"),
    UserForm: () => import("./UserForm")
  },
  setup(props, { refs, root }) {
    const userService = new UserService();
    const roleService = new RoleService();
    const tableHeaders = [
      {
        column: "picture.xx",
        label: "base.img",
        fillable: true,
        avatar: true
      },
      {
        column: "username",
        label: "model.user.username",
        searchable: true,
        sortable: true,
        fillable: true
      },
      {
        column: "email",
        label: "model.user.email",
        searchable: true,
        sortable: true,
        fillable: true
      },
      {
        column: "status",
        label: "model.user.status",
        searchable: true,
        sortable: true,
        fillable: true,
        status: true
      },
      {
        label: "base.tool",
        fillable: true,
        baseTool: true
      }
    ];
    const { toast } = useBase(refs, root);
    //entity
    const initialItem = {
      id: "",
      username: "",
      email: "",
      password: "",
      repassword: "",
      picture: "",
      created_at: "",
      status: false,
      userRoles: []
    };

    const { crud } = useCrudApi(
      refs,
      root,
      userService,
      initialItem,
      tableHeaders
    );

    //fell free to change sort colunm and mode
    //sort.column = "id";
    //sort.mode = "ASC";
    const roleList = ref([]);
    onMounted(() => {
      loadRoles();
    });
    const loadRoles = async () => {
      const { response } = await roleService.get(
        `?perPage=0&sortField=name&sortMode=ASC`
      );
      if (response && response.dataList) {
        roleList.value = response.dataList;
      }
    };

    const onResetUserPassword = async () => {
      crud.isProcessing = true;
      if (!crud.entity) {
        return;
      }
      const { response, error } = await userService.resetUserPassword({
        user_id: crud.entity.id
      });
      crud.isProcessing = false;
      if (error) {
        toast(false, error, { timeout: 5000 });
        return;
      }
      toast(response.status, response.message, { timeout: 5000 });
      crud.openNewForm = false;
    };

    onBeforeUnmount(() => {
    });

    return {
      ...toRefs(crud),
      roleList,
      onResetUserPassword
    };
  }
};
</script>
